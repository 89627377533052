import React from "react"
import { Layout } from "../../components/Layout"
import { WatchWebinarRecording } from "../../commonComponents"
import { WebinarMiddleSection } from "../../WebinarMiddleSection"
import "./styles.css"
import { Helmet } from "react-helmet"

const formId = "9ad8978f-c351-4609-9708-2754eb04c955"

export default function CscWebinar() {
  const styles = {
    fontContact: {
      color: "#fff",
      fontFamily: "DM Sans",
    },
    alignContent: {
      display: "flex",
      alignItems: "center",
    },
  }
  const getDescription = () => {
    return <p>Description</p>
  }
  return (
    <Layout
      title="AI Support Automation: Increase Renewals And Reduce at-Risk Accounts"
      keywords="webinar, proactive AI customer support, customer support automation, Improve CSAT, Improve MTTR"
      description="Learn how IrisAgent powered support automation platform helps in improving CSAT, increase renewals and also improve customer retention by using insights from business."
    >
      <Helmet>
        <link rel="canonical" href="https://irisagent.com/csc-webinar/" />
      </Helmet>
      <section
        style={{
          paddingTop: "40px",
        }}
      >
        <section
          id=" webinar-bg"
          className=" webinar-bg"
          style={{
            // backgroundImage: 'url("/img/resources/post 13.jpeg")',
            minHeight: "620px",
          }}
        >
          <div className=" webinar-container" style={styles.fontContact}>
            <img
              className="webinarButton"
              src="/img/WebinarButton.svg"
              alt=""
            />

            <h1 className="webinar-title" style={styles.fontContact}>
              Increase renewals <br />
              and prevent at-risk <br />
              accounts with proactive
              <br />
              AI-powered support <br />
              automation
            </h1>

            <div className="subtitle-web" style={{ display: "flex" }}>
              <img
                className="calendar"
                src="/img/calendar13Oct.svg"
                alt=""
                width={360}
              />
              <p className="time">Oct 13, 2022 9:45 AM PT</p>
            </div>
            <WatchWebinarRecording></WatchWebinarRecording>
          </div>
          <div className="webinarHeader">
            <img
              src="/img/WebinarHeader.svg"
              alt=""
              className="webinarHeaderImg"
            />
          </div>
        </section>
      </section>

      <WebinarMiddleSection
        mainImage="/img/WebinarSpeakers-csc.svg"
        // imageTextMiniature="/img/WebinarSpeakers.svg"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={<>Webinar Details</>}
        descriptionTextContent={<>A Product-Led growth (PLG) strategy puts the product at the forefront of the customer journey to drive conversion, adoption, retention, and expansion.  In order to achieve the aforementioned, customer success, customer support and product functions need to deliver the best possible customer experience. <br/><br/>Customer Success teams are often in the cross-hairs for poor customer experiences, tasked with improving CSAT, driving renewals, and retention.  However, accessing real-time insights into product issues, potential customer churn, and revenue impact is a blind spot for Customer Success teams. IrisAgent AI-powered Support Automation platform addresses this challenge by unlocking product insights and issues in real-time along with key revenue metrics and delivering them to Customer Success teams in real-time. Customer Success teams thus can avert at-risk accounts, focus on CSAT and maximize renewals.</>}
      />
      {/* <!-- End Middle Section --> */}
    </Layout>
  )
}
